import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';
import { CtyButton } from '../button/CtyButton';

const SvgViewer = ({ svgUrl }: { svgUrl: string }) => {
    const svgContainerRef = useRef(null);
    const svgRef = useRef(null);
    const zoomRef = useRef(null);
    const [selectedColor, setSelectedColor] = useState('#ff0000'); // Default highlighted color
    const [otherColor, setOtherColor] = useState('#003A79');     // Default color for non-highlighted lines
    const fileName = useParams<{ fileName: any }>();
    const [lineClickEventTriggerIndicator, setLineClickEventTriggerIndicator] = useState(1);
    const handleZoom = (direction: 'in' | 'out') => {
        if (!svgRef.current || !zoomRef.current) return;

        const svgElement = d3.select(svgRef.current);
        const zoom = zoomRef.current;

        // Get current transform
        const currentTransform = d3.zoomTransform(svgElement.node());

        // Calculate new scale
        const scale = direction === 'in' ? 1.2 : 0.8;
        const newScale = currentTransform.k * scale;

        // Get SVG dimensions
        const bbox = svgElement.node().getBoundingClientRect();
        const centerX = bbox.width / 2;
        const centerY = bbox.height / 2;

        // Calculate new transform
        const newX = centerX - ((centerX - currentTransform.x) * scale);
        const newY = centerY - ((centerY - currentTransform.y) * scale);

        // Create and apply new transform
        const newTransform = d3.zoomIdentity
            .translate(newX, newY)
            .scale(newScale);

        zoom.transform(svgElement, newTransform);
    };
    const [selectedElementMetadata, setSelectedElementMetadata] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Fetch and inject the SVG into the container
        d3.xml(svgUrl).then((data) => {
            if (svgContainerRef.current) {
                svgContainerRef.current.innerHTML = '';
                svgContainerRef.current.appendChild(data.documentElement);

                // Select the SVG element after it is loaded
                const svgElement = d3.select(svgContainerRef.current).select('svg');
                svgRef.current = svgElement.node();

                // Add stroke-width to all lines
                svgElement.selectAll('line')
                    .style('stroke-width', 2)
                    .style('stroke', otherColor);

                // Set viewBox if not already set
                if (!svgElement.attr('viewBox')) {
                    const width = svgElement.attr('width') || '1000';
                    const height = svgElement.attr('height') || '800';
                    svgElement.attr('viewBox', `0 0 ${width} ${height}`);
                }

                // Ensure the SVG fills its container
                svgElement
                    .style('width', '100%')
                    .style('height', '100%');

                // Create a wrapper group for zoom
                const wrapper = svgElement
                    .append('g')
                    .attr('class', 'zoom-wrapper');

                // Move all existing content into wrapper
                const existingContent = Array.from(svgElement.node().childNodes);
                existingContent.forEach(node => {
                    if (node !== wrapper.node()) {
                        wrapper.node().appendChild(node);
                    }
                });

                // Define zoom behavior
                const zoom = d3.zoom()
                    .scaleExtent([0.1, 4])
                    .on('zoom', (event) => {
                        const transform = event.transform;
                        wrapper.attr('transform', transform.toString());
                    });

                zoomRef.current = zoom;

                // Apply zoom behavior
                svgElement
                    .call(zoom)
                    // Handle mouse wheel zoom
                    .on('wheel.zoom', function (event) {
                        event.preventDefault();

                        const [x, y] = d3.pointer(event, this);
                        let transform = d3.zoomTransform(this);
                        const delta = event.deltaY;
                        const scale = delta > 0 ? 0.8 : 1.2;

                        const newScale = transform.k * scale;
                        const newX = x - ((x - transform.x) * scale);
                        const newY = y - ((y - transform.y) * scale);

                        const newTransform = d3.zoomIdentity
                            .translate(newX, newY)
                            .scale(newScale);

                        zoom.transform(d3.select(this), newTransform);
                    });

                // Handle click events and highlighting
                wrapper.selectAll('g')
                    .filter(function () {
                        const hasMetadata = d3.select(this).select('metadata').node();
                        const hasLines = d3.select(this).selectAll('line').size() > 0;
                        return hasMetadata && hasLines;
                    })
                    .each(function () {
                        const group = d3.select(this);
                        group.selectAll('line')
                            .style("cursor", "pointer")
                            .on('click', function (event) {
                                event.stopPropagation();
                                setLineClickEventTriggerIndicator((prev) => prev + 1);
                                // Reset all lines
                                wrapper.selectAll('line')
                                    .classed('highlighted', false)
                                    .style('stroke', otherColor)
                                    .style('stroke-width', 2);

                                // Highlight clicked group
                                group.selectAll('line')
                                    .classed('highlighted', true)
                                    .style('stroke', selectedColor)
                                    .style('stroke-width', 5);

                                const metadata = group.select('metadata').html();
                                const extractedData = {};
                                const regex = /<e3:(.*?)>(.*?)<\/e3:\1>/g;
                                let match;
                                while ((match = regex.exec(metadata)) !== null) {
                                    const tag = match[1];
                                    const content = match[2];
                                    extractedData[tag] = content;
                                }
                                setSelectedElementMetadata(extractedData);
                                setIsModalOpen(true)
                            });
                    });
            }
        });
    }, [svgUrl]);

    useEffect(() => {
        if (svgRef.current) {
            const svgElement = d3.select(svgRef.current);
            const wrapper = svgElement.select('.zoom-wrapper');

            if (wrapper.empty()) {
                console.warn('No .zoom-wrapper found in the SVG');
                return;
            }

            // Update stroke colors dynamically
            wrapper.selectAll('line')
                .each(function () {
                    const line = d3.select(this);
                    const isHighlighted = line.classed('highlighted');
                    line.style('stroke', isHighlighted ? selectedColor : otherColor)
                        .style('stroke-width', isHighlighted ? 5 : 2);
                });
        } else {
            console.warn('svgRef.current is null or undefined');
        }
    }, [selectedColor, otherColor, lineClickEventTriggerIndicator]);
    return (
        <div className='flex w-full'>
            <div className='border-r border-grey w-[100px] flex flex-col items-center justify-center'>
                <h3 className="font-bold text-center transform -rotate-90 tracking-widest ">
                    {fileName?.fileName}
                </h3>
            </div>
            <div className="flex flex-col gap-4  w-full relative">
                <div className="flex gap-4 items-center justify-center py-4 border-b-1 border-grey">
                    <div className='flex gap-4'>
                        <CtyButton
                            onPress={() => handleZoom('out')}
                            className="p-2 bg-gray-100 rounded hover:bg-gray-200"
                            aria-label="Zoom Out"
                            title='Zoom Out'
                        >
                            <Icon icon="twemoji:minus" />
                        </CtyButton>
                        <CtyButton
                            onPress={() => handleZoom('in')}
                            className="p-2 bg-gray-100 rounded hover:bg-gray-200"
                            aria-label="Zoom In"
                            title='Zoom In'
                        >
                            <Icon icon="qlementine-icons:plus-16" />
                        </CtyButton>
                    </div>
                    <div className="flex items-center gap-2" >
                        <label className="text-sm flex cursor-pointer items-center" >
                            Primary Color:<input
                                type="color"
                                value={selectedColor}
                                onChange={(e) => setSelectedColor(e.target.value)}
                                className="ml-2 w-6 h-6 border-0 border-r-0 rounded-full"
                            />
                        </label>
                        <label className="text-sm cursor-pointer flex items-center">
                            Secondary Color:<input
                                type="color"
                                value={otherColor}
                                onChange={(e) => setOtherColor(e.target.value)}
                                className="ml-2 w-6 h-6 border-0 border-r-0 rounded-full"
                            />
                        </label>
                    </div>
                </div>
                <div
                    ref={svgContainerRef}
                    style={{
                        width: '100%',
                        height: '85vh',
                        overflow: 'hidden',
                        position: 'relative'
                    }}
                >
                    {/* SVG container */}

                </div>
                <div className='absolute top-4 right-0'>
                    {
                        selectedElementMetadata && <SvgMetadataModal
                            metadata={selectedElementMetadata}
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                        />
                    }
                </div>
            </div>
        </div>

    );
};

interface SvgViewerContainerProps {
    svgUrl: string;
}

export default function SvgViewerContainer({ svgUrl }: Readonly<SvgViewerContainerProps>) {
    return (
        <div className='w-full h-[99vh]'>
            <SvgViewer svgUrl={svgUrl} />
        </div>
    );
}



interface SvgMetadataModalProps {
    metadata: any;
    isOpen: boolean;
    onClose: () => void;
}

function SvgMetadataModal(props: Readonly<SvgMetadataModalProps>) {
    const { metadata, isOpen, onClose } = props;
    if (!isOpen) return null;

    return (
        <div className="fixed top-4 right-0  flex items-center justify-center">
            <div className="relative bg-white p-6 rounded-lg w-[500px] max-w-[90%] border-1 ">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    ✕
                </button>
                <h3 className="text-lg font-bold mb-4">Details Info</h3>
                <div>
                    {metadata && Object.keys(metadata).map((key) => (
                        <div key={metadata[key]} className="flex gap-4 border-b border-gray-300 py-2">
                            <p className="w-1/2 font-semibold capitalize">{key}</p>
                            <p className="w-1/2">{metadata[key]}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}