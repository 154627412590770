import {
  AbilityContext,
  checkType,
  CtyShowToast,
  injectPalChatbot,
  type SidebarItem,
} from '@circuitry-ai/doc-ui';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const SidebarItems = () => {
  const { t } = useTranslation();

  const ability = useContext(AbilityContext);

  const OrchestrationSidebarItems = [
    {
      key: 'advisors',
      href: '/advisors',
      icon: 'solar:users-group-two-rounded-outline',
      title: t('AIdvisors'),
    },
    {
      key: 'conversations',
      href: '/conversations',
      icon: 'solar:chat-line-linear',
      title: t('Conversations'),
    },
    {
      key: 'knowledge',
      href: '/knowledge',
      icon: 'solar:database-linear',
      title: t('Knowledge'),
    }
  ]

  if (ability.can("analytics", "Dashboard")) {
    OrchestrationSidebarItems.push({
      key: 'analyst',
      href: '/analytics/list',
      icon: 'carbon:analytics',
      title: t('AInalysts'),
    })
  }

  const AIgentSideNavItems = () => {
    const items = [];
    if (ability.can("pdfscanner", "Agent")) {
      items.push({
        key: 'pdfscanner',
        href: '/aigent/pdfscanner',
        icon: 'material-symbols:scan-outline',
        title: t('PDF Scanner'),
      });
    }
    if (ability.can("automations", "Agent")) {
      items.push({
        key: 'automation',
        href: '/aigent/automation',
        icon: 'carbon:workflow-automation',
        title: t('Automations'),
      });
    }

    return items;
  }
  
  return [
    {
      key: 'dashboard',
      href: '/',
      icon: 'radix-icons:home',
      title: t('Dashboard'),
    },
    {
      key: 'octr',
      title: t('Orchestration Center'),
      items: OrchestrationSidebarItems
    },
    (AIgentSideNavItems().length > 0) && ({
      key: 'agent',
      title: t('AIgents'),
      items: AIgentSideNavItems()
    }),
    {
      key: 'sys',
      title: t('System'),
      items: [
        {
          key: 'settings',
          href: '/settings',
          icon: 'solar:settings-outline',
          title: t('Settings'),
        },
        {
          key: 'help',
          // href: "/help",
          icon: 'carbon:help',
          title: t('Help'),
        },
      ],
    },
    {
      key: 'pal',
      title: (
        <div className="md:hidden block">
          {' '}
          {/* Apply responsive classes here */}
          {t('Launch PAL')}
        </div>
      ),
      items: [
        {
          key: 'New Window',
          icon: 'fluent:window-new-20-regular',
          title: t('New Window'),
          className: ' md:hidden',
          onClick: () => {
            window.open(
              'https://pal.circuitry.ai/',
              '_blank',
              'noopener,noreferrer'
            );
          },
        },
        {
          key: 'Widget',
          icon: 'heroicons:chat-bubble-left-right',
          title: t('Widget'),
          className: ' md:hidden',
          onClick: () => {
            injectPalChatbot(CtyShowToast, checkType);
          },
        },
      ],
    },
  ] as SidebarItem[];
};

export default SidebarItems;
