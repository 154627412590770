import React from 'react';
import { checkType, CtyShowToast } from '../toast/CtyToast';
interface PdfLoadingState {
  [key: string]: {
    view: boolean;
    filename: boolean;
  };
}

export interface pdfViewPayload {
  advisor_id: string;
  source_ids: [
    {
      source_id: string;
      page_labels?: number[];
    }
  ];
}

const formatPageLabels = (pageLabels: string[]): string => {
  if (!pageLabels || pageLabels.length === 0) {
    return '';
  }

  if (pageLabels.length === 1) {
    return pageLabels[0];
  }

  const firstPage = parseInt(pageLabels[0], 10);
  const lastPage = parseInt(pageLabels[pageLabels.length - 1], 10);

  const lowerPage = Math.min(firstPage, lastPage);
  const higherPage = Math.max(firstPage, lastPage);

  return `${lowerPage}-${higherPage}`;
};

const handleKnowledgePdfViewer = async (
  source: any,
  CtyShowToast: any,
  checkType: any,
  getPdfLink: any,
  setIsTableFileLoading: React.Dispatch<React.SetStateAction<PdfLoadingState>>,
  loadingType: 'view' | 'filename'
) => {
  setIsTableFileLoading((prevState) => ({
    ...prevState,
    [source?.source_id]: {
      ...prevState[source?.source_id],
      [loadingType]: true,
    },
  }));
  try {
    const getPdfLinkRequestPayload = {
      source_ids: [
        {
          source_id: source?.source_id,
        },
      ],
    };
    const urlReponse = await getPdfLink(getPdfLinkRequestPayload).unwrap();
    const fullPdfUrl = urlReponse?.data?.[0]?.full_source_presigned_url;
    switch (urlReponse?.data?.[0].source_extension) {
      case 'html':
      case 'htm':
      case 'xml':
      case 'url':
      case 'xlsx':
      case 'xls':
      case 'docx':
      case 'mp4':
      case 'mp3':
      case 'csv':
        window.open(fullPdfUrl, '_blank');
        return null;
      default:
        break;
    }

    const tabKey = Date.now().toString();
    const pdfData = { initialPdfUrl: fullPdfUrl, fullPdfUrl: fullPdfUrl, fileType: urlReponse?.data?.[0].source_extension };
    sessionStorage.setItem(`pdfData-${tabKey}`, JSON.stringify(pdfData));
    const { advisorId, filename } = (fullPdfUrl &&
      extractAdvisorIdAndFilename(fullPdfUrl)) ?? {
      advisorId: '',
      filename: '',
    };
    window.open(`/pdf/${advisorId}/${filename}?tabKey=${tabKey}`, '_blank');
  } catch (e) {
    CtyShowToast(checkType.error, e?.message || 'Error while getting pdf link');
  } finally {
    setIsTableFileLoading((prevState) => ({
      ...prevState,
      [source?.source_id]: {
        ...prevState[source?.source_id],
        [loadingType]: false,
      },
    }));
  }
};
const checkPermissionAndExecute = (
  action: (...args: any) => void | Promise<void>,
  ability: any,
  t: any,
  CtyShowToast: any,
  permission: string,
  resource: string
) => {
  const isRBACDisabled = true;
  if (isRBACDisabled) {
    action();
  } else if (ability.can(permission, resource)) {
    action();
  } else {
    CtyShowToast(
      'warning',
      t(`You do not have permission to ${permission} ${resource}`)
    );
  }
};
const generateCircuitryLogoSvgBlobUrl = (color: string) => {
  //TODO: add support for other SVG files
  const svgString = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.13 344.22">
          <defs>
            <style>
              .cls-1 {
                fill: ${color};
                stroke-width: 0px;
              }
            </style>
          </defs>
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <circle class="cls-1" cx="247.87" cy="247.43" r="19.25"/>
              <path class="cls-1" d="M156.41,338.52c-7.55-7.58-7.53-19.84.04-27.39l36.4-36.31c7.58-7.55,19.84-7.53,27.39.04,7.55,7.58,7.53,19.84-.04,27.39l-36.4,36.31c-7.58,7.55-19.84,7.53-27.39-.04Z"/>
              <path class="cls-1" d="M149.58,290.76c-11.28,11.26-29.55,11.24-40.82-.04h-.01c-11.25-11.3-11.22-29.56.05-40.82l70.99-70.85c15.93-16.21,23.25-39.25,19.55-61.64-6.39-38.78-43.16-65.11-81.93-58.72-38.78,6.4-65.11,43.16-58.72,81.93,1.33,8.05,5.69,23.69,19.98,38.04,3.01,3.02,17.07,17.28,17.07,17.28l-.02.02c11.04,11.31,10.93,29.41-.28,40.58-11.3,11.26-29.56,11.23-40.81-.05-.29-.29-14.37-14.59-16.85-17.07C18.78,200.34,5.98,175.69,1.73,150.02-9.84,79.83,37.83,13.31,108.01,1.73c70.18-11.58,136.71,36.09,148.29,106.28,6.7,40.59-6.56,82.33-35.48,111.65l-.16.16-71.07,70.94Z"/>
            </g>
          </g>
        </svg>
    `;

  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
};

const aggridCommonGridStyles = {
  height: '100%',
  width: '100%',
  '--ag-border-color': 'transparent', // Remove the border
  '--ag-header-background-color': 'white', // Add this line
  '--ag-borders': 'none',
  /* then add back a border between rows */
  '--ag-row-border-color': 'transperent',
  /* and between columns */
  '--ag-cell-horizontal-border': 'transperent',
  '--ag-header-column-separator-color': 'transparent',
  '--ag-odd-row-background-color': '#F9FAFB',
  '--ag-row-hover-color': '#F0F9FF',
  '--ag-checkbox-checked-color': 'rgb(0 58 121)',
  '--ag-range-selection-border-color': 'transparent',
  '--ag-scrollbar-thickness': '30px',
  '--ag-scrollbar-thumb-color': 'rgba(0, 0, 0, 0.5)',
};
function extractAdvisorIdAndFilename(url: string) {
  try {
    // Create a URL object
    const urlObj = new URL(url);

    // Split the pathname of the URL to extract parts
    const pathParts = urlObj.pathname.split('/');
    // The advisorId is located at index 3, and the filename is at index 4
    const advisorId = pathParts[2];
    const filename = pathParts[3];

    return { advisorId, filename };
  } catch (error) {
    console.error('Invalid URL format:', error);
    return null;
  }
}
const injectPalChatbot = (
  CtyShowToast: (type: string, message: string) => void,
  checkType: any
) => {
  // Check if the script is already added to avoid duplication
  if (
    !document.querySelector(
      'script[src="https://cdn.circuitry.ai/pal/pal-chatbot.umd.js"]'
    )
  ) {
    // Create the script element
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://cdn.circuitry.ai/pal/pal-chatbot.umd.js';

    // Append the script to the document's head or body
    document.body.appendChild(script);
  }

  // Check if <pal-chatbot> already exists to avoid duplicates
  if (!document.querySelector('pal-chatbot')) {
    // Create the <pal-chatbot> element and append it to the body or a specific container
    const palChatbotElement = document.createElement('pal-chatbot');
    document.body.appendChild(palChatbotElement); // You can append it to any specific container if needed
  }
  setTimeout(() => {
    // Find the shadow root of the pal-chatbot element
    const palChatbot = document.querySelector('pal-chatbot');
    const shadowRoot = palChatbot?.shadowRoot;

    if (shadowRoot) {
      // Now query the #pal-chatbot-icon inside the shadow root
      const palChatbotIcon = shadowRoot.querySelector('#pal-chatbot-icon');

      if (palChatbotIcon) {
        // Perform the click action
        palChatbotIcon.click();
      } else {
        console.warn('PAL Chatbot icon not found inside shadow DOM');
      }
    } else {
      CtyShowToast(checkType.error, 'PAL Chatbot not found');
    }
  }, 1500);
};

const sharedPDFViewHandler = async (
  loadingState: string,
  setPdfLoadingIndex: React.Dispatch<React.SetStateAction<string>>,
  getPdfLinkRequestPayload: pdfViewPayload,
  getPdfLink: any
) => {
  setPdfLoadingIndex(loadingState);
  try {
    const urlReponse = await getPdfLink(getPdfLinkRequestPayload).unwrap();

    const fullPdfUrl = urlReponse?.data?.[0]?.full_source_presigned_url;
    const pagePdfUrl = urlReponse?.data?.[0]?.page_labels_presigned_url;
    const tabKey = Date.now().toString();
    switch (urlReponse?.data?.[0].source_extension) {
      case 'html':
      case 'htm':
      case 'xml':
      case 'url':
      case 'xlsx':
      case 'xls':
      case 'docx':
      case 'mp4':
      case 'mp3':
      case 'csv':
        window.open(fullPdfUrl, '_blank');
        return null;
      default:
        break;
    }
    const pdfData = { initialPdfUrl: pagePdfUrl, fullPdfUrl: fullPdfUrl, fileType: urlReponse?.data?.[0].source_extension };
    sessionStorage.setItem(`pdfData-${tabKey}`, JSON.stringify(pdfData));
    const { advisorId, filename } = (fullPdfUrl &&
      extractAdvisorIdAndFilename(fullPdfUrl)) ?? {
      advisorId: '',
      filename: '',
    };
    window.open(`/pdf/${advisorId}/${filename}?tabKey=${tabKey}`, '_blank');
  } catch (e) {
    CtyShowToast(
      checkType.error,
      (e as Error)?.message || 'Error while getting pdf link'
    );
  } finally {
    setPdfLoadingIndex('');
  }
};
const RichTextEditorThemeConfig = {
  code: 'editor-code',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  image: 'editor-image',
  link: 'editor-link',
  list: {
    listitem: 'editor-listitem',
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
  },
  ltr: 'ltr',
  paragraph: 'editor-paragraph',
  placeholder: 'editor-placeholder',
  quote: 'editor-quote',
  rtl: 'rtl',
  text: {
    bold: 'editor-text-bold',
    code: 'editor-text-code',
    hashtag: 'editor-text-hashtag',
    italic: 'editor-text-italic',
    overflowed: 'editor-text-overflowed',
    strikethrough: 'editor-text-strikethrough',
    underline: 'editor-text-underline',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
  },
};
const getUserInitials = (nickname = '') => nickname.split('.').filter(Boolean).map(word => word[0]?.toUpperCase() || '').join('').slice(0, 2);
const getUserName = (isAuthenticated: boolean, user: any) => isAuthenticated ? user?.name?.trim() ?? 'You' : 'Guest';

export {
  formatPageLabels,
  handleKnowledgePdfViewer,
  checkPermissionAndExecute,
  aggridCommonGridStyles,
  generateCircuitryLogoSvgBlobUrl,
  extractAdvisorIdAndFilename,
  injectPalChatbot,
  sharedPDFViewHandler, getUserInitials, getUserName,
  RichTextEditorThemeConfig,
};
