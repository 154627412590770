import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CtyButton } from "../button/CtyButton";
import SvgViewerContainer from "./svgViewer";

function FileViewer() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabKey = queryParams.get('tabKey');
    const fileName = useParams<{ fileName: any }>();
    const pdfData = JSON.parse(
        sessionStorage.getItem(`pdfData-${tabKey}`) ?? '{}'
    );
    const fileType = pdfData.fileType ?? 'pdf';
    const [isFullPdfOpen, setIsFullPdfOpen] = useState(false);
    const [currentFileUrl, setCurrentFileUrl] = useState(pdfData.initialPdfUrl ?? pdfData.fullPdfUrl);

    const handlePdfChanger = () => {
        setIsFullPdfOpen(!isFullPdfOpen);
        if (isFullPdfOpen) {
            setCurrentFileUrl(pdfData.initialPdfUrl);
        } else {
            setCurrentFileUrl(pdfData.fullPdfUrl);
        }
    }
    const renderFileViewer = () => {
        switch (fileType) {
            case 'pdf':
                return (
                    <div className="h-full w-full bg-[#323639]">
                        <div className="w-full flex relative top-[1rem] left-[4rem] mb-[10px]">
                            <CtyButton className="border-radius-0 bg-[white]" variant="solid" onPress={handlePdfChanger}>{isFullPdfOpen ? 'View Single Page' : `View Entire ${fileName?.fileName}`}</CtyButton>
                        </div>
                        <iframe src={currentFileUrl} className="w-full h-[100vh]" title="pdf"></iframe>
                    </div>
                );
            case 'svg':
                return (
                    <div className="h-full w-full bg-[#fff]">
                        <SvgViewerContainer svgUrl={currentFileUrl} />

                    </div>
                );
            case 'html':
            case 'htm':
            case 'xml':
            case 'url':
            case 'xlsx':
            case 'xls':
            case 'docx':
            case 'mp4':
            case 'mp3':
            case 'csv':
                window.open(currentFileUrl, '_blank');
                return null;

            default:
                window.open(currentFileUrl, '_blank');
                return null;
        }
    };

    return (
        <div className="h-full w-full">
            {renderFileViewer()}
        </div>
    );
}

export { FileViewer };
