import { api, handleErrorResponse, responseArgs } from "../api";

export const pdfAnalyzeApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getPDFUploadPresignedUrl: builder.mutation<string, { name: string }>({
            query: ({ name }) => ({
                url: `/api/v1/conversations/analyzer`,
                method: 'POST',
                body: JSON.stringify({ s3_paths: [name] })
            }),
            transformResponse: (response: responseArgs) => response.data?.[0],
            transformErrorResponse: handleErrorResponse,
        })
    })
});

export const { useGetPDFUploadPresignedUrlMutation } = pdfAnalyzeApiSlice;