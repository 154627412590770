import apiEndPoints from "../../apiconstants";
import { api, handleErrorResponse, responseArgs } from "../api";

export interface speechToken {
    token: string;
    region: string;
}

export const speechTokenApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getToken: builder.query<speechToken, void>({
            query: () => apiEndPoints?.getSpeechToken,
            transformResponse: (response: responseArgs) => response.data as speechToken,
            transformErrorResponse: handleErrorResponse,
        }),
    })
});

export const { useGetTokenQuery } = speechTokenApiSlice;